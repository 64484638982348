<template lang="html" src="./products.html"/>

<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],
  props:{
    planAndAddOnsData: {
      type: Object,
    },
  },
  data() {
    return {
      subscriptionData: null,
      addonsSelected: [],
      reducedAddons: [],
      value: 0,
      addons: [
        { id: 9, name: "Pronto-atendimento Médico", serviceValue: 24.90, description: "<p class='description'>O nosso serviço está disponível 24h por dia, 7 dias por semana via telemedicina para que você e sua família possam receber orientação médica no conforto do seu lar, sem precisar se deslocar até uma clínica ou hospital e pode ser utilizado de maneira ilimitada. As orientações acontecem por meio de vídeo chamada no seu celular ou computador e todas as receitas de medicamentos são emitidas digitalmente.</p>", media: require("@/assets/svgs/myPlan/planUpgrade/products/descontos-em-medicamentos.png") },
        { id: 4, name: "Orientação médica por telefone", serviceValue: 9.90, description: "<p class='description'>Este serviço conta com uma equipe multidisciplinar de médicos e enfermeiros disponíveis 24h por dia, 7 dias por semana para atender à todas as suas dúvidas e necessidades de atendimentos em saúde. A orientação acontece de maneira receptiva através de ligações gratuitas e ilimitadas</p>", media: require("@/assets/svgs/myPlan/planUpgrade/products/medical-advice-by-phone.svg") },
        { id: 18, name: "Orientação psicológica por telefone", serviceValue: 8.90, description: "<p class='description'>O nosso serviço de orientações pscológicas te ajuda com situações do dia a dia como estresse, problemas familiares, ansiedade, depressão, traumas, relacionamentos interpessoais, entre outros. A orientação acontece através de contato telefônico gratuito e ilimitado.</p>", media: require("@/assets/svgs/myPlan/planUpgrade/products/insurance-combo.svg") },
        { id: 20, name: "Combo seguros", serviceValue: 7.90, description: "<p class='description'>Neste pacote estão inclusos os seguintes serviços: Seguro de Vida (por morte ou invalidez), Assistência Residencial, Assistência Funeral e Sorteios Mensais. Confira as vantagens de cada um:</p><ul class='description'><li>- Seguro de Vida por Morte: Esta é a melhor forma de proteger financeiramente quem você ama. Se vier a falecer, você garante o pagamento imediato de uma indenização de R$ 5.000,00 ao(s) beneficiário(s) que você escolher.</li><li>- Assistência Funeral: Esta assistência garante, em caso de morte, a realização ou reembolso do funeral do titular no valor de R$ 3.500,00.</li><li>- Seguro de Vida por Invalidez: Este serviço é a melhor forma de você se proteger financeiramente em caso de eventualidades. Você pode contar com uma indenização de até R$ 5.000,00 caso você não possa trabalhar mais devido a um acidente que cause a perda total, ou parcial, de membros ou órgãos.</li><li>- Assistência Residencial: Com esta cobertura, o seu lar recebe um cuidado especial. Estão cobertos serviços de encanador, eletricista, chaveiro, pintor, pedreiro, entre outros para te ajudar no seu dia a dia.</li><li>- Sorteios Mensais: Você concorrerá a prêmios de R$ 5.000,00 em dinheiro em um sorteio mensal realizado pela Loteria Federal. Boa sorte!</li></ul>", media: require("@/assets/svgs/myPlan/planUpgrade/products/psychological-orientation.svg") },
        { id: 3, name: "Descontos em medicamentos de até 85%", serviceValue: 3.90, description: "<p class='description'>Descontos em medicamentos, de até 85%, em mais de 14 mil farmácias por todo o Brasil. </p>", media: require("@/assets/svgs/myPlan/planUpgrade/products/prompt-medical-care.svg") },
      ],
    }
  },
  mounted() {
    const vm = this;
    vm.getSelectedAddons();
  },
  methods: {
    getSelectedAddons(){
      const vm = this;
      if(!vm.testNullUndefinedEmpty(vm.$props.planAndAddOnsData)){
        vm.subscriptionData = vm.$props.planAndAddOnsData;
      } else {
        vm.subscriptionData =  JSON.parse(localStorage.getItem("planAndAddOnsData"));
      }
      console.log(vm.subscriptionData);
      if(!vm.testNullUndefinedEmpty(vm.subscriptionData.knowMoreAddonId)){
        window.scrollTo(0, 0);
        document.getElementById(`addon-${vm.subscriptionData.knowMoreAddonId}`).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start"  });
      }
    },
    goToOptionsScreen(addon){
      const vm = this;
      const addonsSelected = vm.subscriptionData.addons;
      addonsSelected.push(addon);
      vm.reducedAddons = [];
      vm.subscriptionData.addons.forEach((item) => {
        var duplicated  = vm.reducedAddons.findIndex(redItem => {
          return item.id == redItem.id;
        }) > -1;

        if(!duplicated) {
          if(item.id == addon.id){
            item.checked = true;
            vm.value += item.value;
          }
          vm.reducedAddons.push(item);
        }
      });
      const planAndAddOnsData = {
        plan: vm.subscriptionData.plan,
        addons: vm.reducedAddons,
        total: parseFloat(vm.value+vm.subscriptionData.total),
      };
      localStorage.setItem("planAndAddOnsData", JSON.stringify(planAndAddOnsData));
      vm.$router.push({
        name: "plan-upgrade",
      });
    },
  },
}
</script>

<style lang="scss" src="./products.scss"/>
